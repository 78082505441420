import React, { useState, useEffect, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Button, IconButton, Grid, Box, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { FileRow } from '../../types';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface MultiRowFileUploaderProps {
  setFileRows: (fileRows: FileRow[]) => void;
}

const MultiRowFileUploader: React.FC<MultiRowFileUploaderProps> = ({ setFileRows }) => {
  const [fileRows, setLocalFileRows] = useState<FileRow[]>([{ id: Date.now(), file: null }]);

  useEffect(() => {
    setFileRows(fileRows);
  }, [fileRows, setFileRows]);

  const handleAddRow = () => {
    setLocalFileRows([...fileRows, { id: Date.now(), file: null }]);
  };

  const handleRemoveRow = (id: number) => {
    setLocalFileRows(fileRows.filter(row => row.id !== id));
  };

  const handleFileChange = (id: number, event: ChangeEvent<HTMLInputElement>) => {
    const updatedRows = fileRows.map(row => {
      if (row.id === id && event.target.files) {
        return { ...row, file: event.target.files[0] };
      }
      return row;
    });
    setLocalFileRows(updatedRows);
  };

  return (
    <Box sx={{ maxWidth: '600px', mx: 'auto', mt: 4 }}>
      {fileRows.map((row, index) => (
        <Grid container spacing={2} key={row.id} alignItems="center">
          <Grid item xs={7}>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              {row.file ? 'Change file' : 'Upload file'}
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => handleFileChange(row.id, event)}
              />
            </Button>
          </Grid>
          <Grid item xs={4}>
            {row.file && (
              <Typography variant="body2" noWrap>
                {row.file.name}
              </Typography>
            )}
          </Grid>
          <Grid item xs={1}>
            {index === 0 ? (
              <IconButton onClick={handleAddRow} color="primary">
                <Add />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleRemoveRow(row.id)} color="secondary">
                <Remove />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default MultiRowFileUploader;
