import { Route, Routes } from 'react-router-dom'
import MedicalIntake from '../MedicalIntake'
import Layout from '../Layout/Layout'

const Router = () => {
    return (
        <Routes>
            <Route path="/initialprefs" element={<Layout><MedicalIntake /></Layout>} />
        </Routes>
    )
}

export default Router