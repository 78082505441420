import React, { useState, useEffect, useRef } from 'react';
import { Form } from "@formio/react";
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import MultiRowFileUploader from "./MultiRowFileUploader/MultiRowFileUploader";
import { FileRow } from '../types';

interface Submission {
  data: {
    radio: string;
  };
}

const MedicalIntake: React.FC = () => {
  const FORM_URL = `${process.env.REACT_APP_MEDICAL_INTAKE_FORM_URL}`
  const DOC_API_ENDPOINT = `${process.env.REACT_APP_DOC_API_ENDPOINT}`

  const [fileRows, setFileRows] = useState<FileRow[]>([{ id: Date.now(), file: null }]);
  const isUploaderVisibleRef = useRef<boolean>(false); // Ref to track visibility
  const [isDocumentUploaded, setIsDocumentUploaded] = useState<boolean>(false);
  const [submitButton, setSubmitButton] = useState<HTMLButtonElement | null>(null); 

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    const injectDiv = () => {
      const uploaderDiv = document.getElementById('file-uploader');
      if (uploaderDiv && !uploaderDiv.dataset.rootRendered) {
        console.log('Uploader div is now in the DOM');
        const root = createRoot(uploaderDiv);
        root.render(
          <MultiRowFileUploader 
            setFileRows={(rows: FileRow[]) => {
              setFileRows(rows);
              if (rows.length > 0 && rows.every(row => row.file)) {
                setIsDocumentUploaded(true);
              } else {
                setIsDocumentUploaded(false);
              }
            }} 
          />
        );
        uploaderDiv.dataset.rootRendered = 'true'; // Prevent re-rendering
        isUploaderVisibleRef.current = true;
      } else if (!uploaderDiv) {
        isUploaderVisibleRef.current = false; // Reset visibility if the uploader div is not present
      }
    };

    injectDiv();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          injectDiv();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const monitorSubmitButton = () => {
      const button = document.querySelector('.btn-wizard-nav-submit') as HTMLButtonElement;
      if (button) {
        setSubmitButton(button);
      }
    };

    monitorSubmitButton();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          monitorSubmitButton();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (submitButton) {
      submitButton.disabled = isUploaderVisibleRef.current && !isDocumentUploaded;
    }
  }, [submitButton, isDocumentUploaded]);

  const handleFileUploadSubmit = async () => {
    console.log('File upload submission:', fileRows);
    const formData = new FormData();

    fileRows.forEach(row => {
      if (row.file) {
        formData.append('files', row.file);
      }
    });

    try {
      const response = await axios.post(DOC_API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const writeToSupabaseTable = (submission: Submission) => {
    const radio = submission.data.radio;

    switch (radio) {
      case 'I will give you credentials to my electronic health portal':
        console.log('1st radio');
        break;
      case 'I have the records and can upload them electronically now':
        console.log('2nd radio');
        break;
      default:
        console.log(`no action taken.`);
    }
  };

  const onSubmit = async (submission: Submission) => {
    console.log("PARAMS", id);
    console.log("from submit", isUploaderVisibleRef.current); // Log visibility from the ref

    if (isUploaderVisibleRef.current){
      await handleFileUploadSubmit();
    }
    writeToSupabaseTable(submission);
  };

  return (
    <div className="App">
      <Form src={FORM_URL} onSubmit={onSubmit} />
    </div>
  );
};

export default MedicalIntake;
